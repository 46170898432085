import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePrefs, useLabels } from 'wsm-common-data';
import VehicleCardData from './VehicleCardData';
import HighlightedAttributeContainer from './HighlightedAttributeContainer';
import WebIntegrationPlaceholder from '../../WebIntegrationPlaceholder';
import { enhanceHighlightedAttributes } from '../../../utilities/highlightedAttributes';
import {
	selectIsGridLayout,
	selectIsTabbedLayout
} from '../../../features/layoutSlice';

const Details = ({
	vehicle,
	incentives,
	accounts,
	isDisplayingEPrice,
	setIsDisplayingEPrice,
	showExpandedPricing,
	setShowExpandedPricing
}) => {
	const { accountId } = useSelector((state) => state.widgetInfo);
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { showPricingSummary } = usePrefs();
	const labels = useLabels();

	const gridPlaceholderLocation =
		showPricingSummary === 'true' &&
		!useSimplifiedListing &&
		(isGridLayout || isTabbedLayout) ? (
			<WebIntegrationPlaceholder location="vehicle-payments-primary" />
		) : (
			''
		);

	const highlightedAttributes = enhanceHighlightedAttributes(
		vehicle,
		accounts,
		useSimplifiedListing
	);

	const containerRef = useRef();

	useEffect(() => {
		const tablist = containerRef.current.querySelector('[role=tablist]');
		if (!tablist) {
			return;
		}
		tablist.setAttribute('aria-label', labels.get('VEHICLE_SUMMARY'));
	}, [labels]);

	return (
		<div ref={containerRef}>
			{gridPlaceholderLocation}
			{!useSimplifiedListing && (
				// TODO: Simplified Listing moves Highlighted Attributes into VehicleCardData component
				<HighlightedAttributeContainer
					highlightedAttributes={highlightedAttributes}
					accountInfo={{ ...accounts[vehicle.accountId] }}
				/>
			)}
			<VehicleCardData
				accountId={accountId}
				accountInfo={{ ...accounts[vehicle.accountId] }}
				incentives={incentives}
				vehicle={vehicle}
				highlightedAttributes={highlightedAttributes}
				isDisplayingEPrice={isDisplayingEPrice}
				setIsDisplayingEPrice={setIsDisplayingEPrice}
				showExpandedPricing={showExpandedPricing}
				setShowExpandedPricing={setShowExpandedPricing}
			/>
		</div>
	);
};

Details.propTypes = {
	vehicle: PropTypes.shape({
		title: PropTypes.arrayOf(PropTypes.string),
		uuid: PropTypes.string,
		link: PropTypes.string,
		accountId: PropTypes.string,
		condition: PropTypes.string,
		callout: PropTypes.arrayOf(PropTypes.shape({})),
		packages: PropTypes.arrayOf(PropTypes.string),
		incentiveIds: PropTypes.arrayOf(PropTypes.string),
		bestIncentiveIds: PropTypes.arrayOf(PropTypes.string),
		inventoryButtons: PropTypes.arrayOf(PropTypes.shape({})),
		images: PropTypes.arrayOf(PropTypes.shape({})),
		attributes: PropTypes.arrayOf(PropTypes.shape({})),
		highlightedAttributes: PropTypes.arrayOf(PropTypes.shape({})),
		pricing: PropTypes.shape({}),
		year: PropTypes.number,
		model: PropTypes.string,
		trim: PropTypes.string,
		make: PropTypes.string,
		vin: PropTypes.string,
		fuelType: PropTypes.string
	}).isRequired,
	incentives: PropTypes.shape({}),
	accounts: PropTypes.shape({}),
	accountId: PropTypes.string.isRequired,
	isDisplayingEPrice: PropTypes.bool.isRequired,
	setIsDisplayingEPrice: PropTypes.func.isRequired,
	showExpandedPricing: PropTypes.bool.isRequired,
	setShowExpandedPricing: PropTypes.func.isRequired
};

Details.defaultProps = {
	incentives: {},
	accounts: {}
};

export default Details;
