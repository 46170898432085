import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * Reloads srp widgets once data from data bus widget is present on page.
 * @param widget
 * @param registerType - determines which register notification method to call in ws-inv-data.
 * Either 'facet' or 'inventory'
 */

export const waitForInventory = (widgetThis) => {
	let waitForInvDataHandle;
	const {
		props: { widgetName }
	} = widgetThis;
	function updateWidget(arg) {
		widgetThis.forceUpdate(() => {
			if (Array.isArray(arg) && arg.length && widgetName) {
				const widgetTrim = widgetName.trim();
				const {
					timingData: { requestDuration, initialStartTime }
				} = arg[0];
				const dateNow = Date.now();
				setNewRelicCustomAttribute(
					`${widgetTrim}-request-duration`,
					requestDuration
				);
				setNewRelicCustomAttribute(
					`${widgetTrim}-render-duration`,
					dateNow - initialStartTime - requestDuration
				);
				setNewRelicCustomAttribute(
					`${widgetTrim}-total-duration`,
					dateNow - initialStartTime
				);
			}
		});
	}

	function checkForInvData() {
		if (isBrowser && window.DDC?.InvData?.inventory?.inventory) {
			if (window.DDC?.pubsub) {
				window.DDC.pubsub.subscribe(
					'ws-inv-data/inventory',
					updateWidget
				);
			}
			clearInterval(waitForInvDataHandle);
			updateWidget();
		}
	}
	waitForInvDataHandle = setInterval(checkForInvData, 100);
};
