import React from 'react';
import { PromoWrapper } from 'wsm-specials-display';
import CtaCard from '../components/CtaCard';
import WebIntegrationListingsPlaceholder from '../components/WebIntegrationListingsPlaceholder';
import IntersectionWrapper from '../components/IntersectionWrapper';
import GenericAdCard from '../components/GenericAdCard';
import {
	inventoryPageSizes,
	MIN_COLUMNS_FOR_POSITIONING
} from '../global-constants';

/**
 * Utility for generating listings placeholders wrapped with Intersection Observer
 * @param {string} key
 * @param {*} options
 * @param {*} callback
 * @param {*} ref
 * @returns
 */
const intersectionWrapperPlaceholderFactory = (key, options, callback, ref) => {
	return (
		<IntersectionWrapper
			key={key}
			observerOptions={options}
			shouldFullyRender
			onIntersectCallback={callback}
		>
			<WebIntegrationListingsPlaceholder
				location={key}
				observerRef={ref}
			/>
		</IntersectionWrapper>
	);
};

/**
 * Merges the vehicle cards with CTAs in the provided positions
 * @param {VehicleCard[]} cards Array to be modified which holds the vehicle inventory, cards, and third party ads
 * @param {CTA[]} ctas Array of CTAs fetched from the store
 * @param {number[]} positions Array of positions where CTAs should be placed
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @returns {VehicleCard[]} Array of VehicleCards merged with CTA cards
 */
export const mergeInventoryAndCtas = (cards, ctas, positions, options) => {
	const { observerOptions, observerRef, observerCallback } = options;
	const newCards = [...cards];
	ctas.forEach((cta, i) => {
		const ctaKey = `cta-card-${cta.position}`;
		if (positions[i]) {
			newCards.splice(
				positions[cta.position - 1],
				0,
				<IntersectionWrapper
					key={ctaKey}
					observerOptions={observerOptions}
					shouldFullyRender
					onIntersectCallback={observerCallback}
				>
					<CtaCard key={ctaKey} observerRef={observerRef} {...cta} />
				</IntersectionWrapper>
			);
		}
	});
	return newCards;
};

/**
 * Method which merges vehicle cards with Ads injected by the WIAPI in correct positions, most specific to Hyundai
 * @param {VehicleCard[]} cards Array to be modified which holds the vehicle inventory, cards, and third party ads
 * @param {number[]} positions Array of positions for Ads to be placed in the vehicle inventory card list
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @returns {VehicleCard[]} Array of VehicleCards merged with 3rd party AdCards
 */
export const mergeInventoryAndAds = (cards, positions, options) => {
	const { observerOptions, observerCallback } = options;
	const newCards = [...cards];

	positions.forEach((pos, i) => {
		const adKey = `listings-ad-${i + 1}`;
		newCards.splice(
			pos,
			0,
			<IntersectionWrapper
				key={adKey}
				observerOptions={observerOptions}
				shouldFullyRender
				onIntersectCallback={observerCallback}
			>
				<GenericAdCard
					dataLocation={adKey}
					position={i + 1}
					type="listings-ad"
				/>
			</IntersectionWrapper>
		);
	});
	return newCards;
};

/**
 * Merges VehicleCard list which already contains CTAs or Ads with Banner placeholders. Banners inserted last since they take up whole row.
 * Method currently merges 5 banners into list, one for My-Wallet, one as a placeholder at the end of page, and 3 in between
 * This will always display 5 listings-placeholders for insertion, so for small page sizes extras will all be stacked at bottom of the page
 * @param {T[]} cards VehicleCard list
 * @param {number} columns Number of columns for each row of cards
 * @param {Object} options Options to be provided to IntersectionWrapper
 * @param {Object} options.observerOptions
 * @param {Object} options.observerRef
 * @param {Object} options.observerCallback
 * @param {number} invSize Size of vehicle inventory shown on the page
 * @param {number} additionalListings Number of additional placeholders to insert inbetween first and last listing placeholders, defaults to 3
 * @returns
 */
export const mergeAllWithPlaceholders = (
	cards,
	columns,
	options,
	invSize,
	additionalListings = 3
) => {
	const { observerOptions, observerRef, observerCallback } = options;
	const newCards = [...cards];
	// we use MIN_COLUMNS_FOR_POSITIONING here so that when there's less than 2 columns we use 2 in our calculations for spacing purposes
	const newColumns =
		columns < MIN_COLUMNS_FOR_POSITIONING
			? MIN_COLUMNS_FOR_POSITIONING
			: columns;
	let placeholder = 1;
	let placeholderKey = `listings-placeholder-${placeholder}`;
	// place first listing-placeholder at the end of first row
	newCards.splice(
		newColumns,
		0,
		intersectionWrapperPlaceholderFactory(
			placeholderKey,
			observerOptions,
			observerCallback,
			observerRef
		)
	);
	placeholder += 1;
	placeholderKey = `listings-placeholder-${placeholder}`;

	// we require this accommodator value to account for previous additions of listings placeholders for correct placement
	let accommodatePreviousAdditions = 1;

	// insert 3 or more additional listings-placeholders in vehicle card list
	const { MD_PAGE_SIZE } = inventoryPageSizes;
	for (let i = 0; i < additionalListings; i++) {
		const pos =
			i === 0 || invSize <= MD_PAGE_SIZE
				? newColumns * placeholder + accommodatePreviousAdditions
				: newColumns * (placeholder + i) + accommodatePreviousAdditions;
		newCards.splice(
			pos,
			0,
			intersectionWrapperPlaceholderFactory(
				placeholderKey,
				observerOptions,
				observerCallback,
				observerRef
			)
		);
		placeholder += 1;
		placeholderKey = `listings-placeholder-${placeholder}`;
		accommodatePreviousAdditions += 1;
	}
	// place last 3rd party placeholder
	newCards.push(
		intersectionWrapperPlaceholderFactory(
			placeholderKey,
			observerOptions,
			observerCallback,
			observerRef
		)
	);
	return newCards;
};

/**
 * Utility function to insert SCM promos into Vehicle Card List
 * @param {Object[]} cards The Vehicle Card list for the SRP which we add additional content to
 * @param {Object[]} scmList List of SCM promos
 * @param {number[]} positions The list of positions to insert promos
 * @param {Object} accountData Data stored at window.DDC.InvData.getAccountData();
 * @return {Object[]} Updated list with SCM promos inserted
 */
export const mergeInventoryAndSCMPromos = (
	cards,
	scmList,
	positions,
	accountData,
	personalized,
	isMobile = false
) => {
	const newCards = [...cards];

	positions.forEach((pos, i) => {
		const scmKey = `scm-card-${i + 1}`;
		newCards.splice(
			pos,
			0,
			<li
				data-location={`scm-promo-card-${i + 1}`}
				key={scmKey}
				style={
					isMobile
						? { maxWidth: 'unset', margin: 0, marginBottom: '16px' }
						: { height: 'auto' }
				}
				className="vehicle-card-detailed box box-border"
			>
				<PromoWrapper
					promos={scmList[i]}
					account={accountData}
					personalized={personalized}
				/>
			</li>
		);
	});

	return newCards;
};
