import React, { useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, useRequestData } from 'wsm-common-data';
import { removeAllFilters } from 'wsm-srp-utilities';
import { trackEvent } from 'ddc-track-event';
import { addNewRelicPageAction } from 'ddc-new-relic';
import SVG from 'react-inlinesvg';
import ClearFiltersButton from '../ClearFiltersButton';
import FacetGeoLocation from './FacetGeoLocation';
import FilterItemList from './FilterItemList';
import './LowResults.scss';
import InventoryLinks from './InventoryLinks';

const noResultSvg = (
	<SVG
		src="/static/assets/illustrations/spot/no-results.svg"
		aria-hidden="true"
		height={100}
		width={236}
		loader={
			<div
				style={{
					width: `${236}px`,
					height: `${100}px`
				}}
			/>
		}
	/>
);

const LowResults = ({ isNoResults, appliedFilters }) => {
	const { widgetName, windowId } = useRequestData();
	const { isSmallScreen } = useSelector((state) => state.layout);
	const { geoZip } =
		(isBrowser &&
			window.DDC?.InvData?.srpReady &&
			window.DDC?.InvData?.getGeoData()) ||
		{};
	const labels = useLabels();

	useEffect(() => {
		if (isNoResults) {
			trackEvent(widgetName, windowId, {
				element: 'No vehicle results',
				action: 'impression',
				result: 'No results message presented',
				nonInteractive: true
			});
			addNewRelicPageAction('WS INV LISTING NO RESULTS', {
				isNoResults: true,
				appliedFilters
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearButton = (
		<div className="srp-low-results-clear-filters text-center">
			<ClearFiltersButton
				handleClick={() =>
					removeAllFilters(
						labels.get('CLEAR_ALL_FILTERS'),
						widgetName,
						windowId
					)
				}
			>
				{labels.get('CLEAR_ALL_FILTERS')}
			</ClearFiltersButton>
		</div>
	);

	return (
		<li
			className={setClassNames([
				isNoResults
					? 'srp-no-results box box-border'
					: 'srp-low-results border-lg-top border-bottom',
				'vehicle-card',
				'vehicle-card-detailed',
				'vehicle-card-horizontal',
				isSmallScreen ? 'low-result-mobile' : ''
			])}
		>
			<div className="srp-low-results-wrapper">
				<h1
					className={`${
						isSmallScreen ? 'display-3' : 'display-4'
					} mt-0 text-center divider-centered-border-bottom divider-primary divider-2`}
				>
					{appliedFilters?.length === 0 && isNoResults
						? labels.get('CHECK_BACK_SOON')
						: labels.get('NO_RESULTS_MESSAGE')}
				</h1>
				<div className="srp-low-result-media-wrapper text-center mb-6">
					{noResultSvg}
				</div>
				<div className="srp-low-result-filters-wrapper">
					<FilterItemList appliedFilters={appliedFilters} />
				</div>
				{geoZip && appliedFilters?.length > 0 && (
					<FacetGeoLocation geoZip={geoZip} />
				)}

				{appliedFilters?.length === 0 && isNoResults ? (
					<InventoryLinks />
				) : (
					clearButton
				)}
			</div>
		</li>
	);
};

LowResults.propTypes = {
	isNoResults: PropTypes.bool,
	appliedFilters: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			values: PropTypes.arrayOf(PropTypes.shape({}))
		})
	)
};

LowResults.defaultProps = {
	isNoResults: false,
	appliedFilters: []
};

export default LowResults;
