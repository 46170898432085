export const SET_WIDGET_INFO = 'SET_WIDGET_INFO';
export const SET_LAYOUT = 'SET_LAYOUT';
export const ADD_COMPARE_VEHICLE = 'ADD_COMPARE_VEHICLE';
export const REMOVE_COMPARE_VEHICLE = 'REMOVE_COMPARE_VEHICLE';
export const REMOVE_ALL_COMPARE_VEHICLES = 'REMOVE_ALL_COMPARE_VEHICLES';
export const SET_MODAL_SETTINGS = 'SET_MODAL_SETTINGS';
export const SET_OFFSITE_DATA = 'SET_OFFSITE_DATA';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_CTA_CARD_ITEMLIST = 'SET_CTA_CARD_ITEMLIST';
export const SET_FLAGS = 'SET_FLAGS';
